
    import {ref} from 'vue'
    import {useToast} from "primevue/usetoast";
    import moment from 'moment';
    import {useStore} from "vuex";
    import {Criteria} from "@/models/criteria.models";
    import EvaluationRepository from "@/services/EvaluationRepository";
    import {UserInfo} from "@/models/userInfo.models";
    import {UserInfoDetail} from "@/models/userInfoDetail.models";
    import {EvaluationDetail} from "@/models/evaluationDetail.models";
    import {EvaluationInfo} from "@/models/evaluationInfo.models";

    export default {
        props: {
            id: Number,
            time: Number,
        },
        setup(props) {
            const criteria = ref([] as Criteria[]);
            const toast = useToast();
            const result = ref("");
            const useInfo = ref({} as UserInfoDetail);
            const evaldetail = ref({} as EvaluationDetail);
            const evalInfo = ref({} as EvaluationInfo);
            const store = useStore();
            const success = ref(false);
            const confirm = ref(false);
            const note = ref("");

            EvaluationRepository.getUserInfo(props.id)
                .then(response => {
                    useInfo.value = response.data;
                    EvaluationRepository.getCriteria(useInfo.value.team)
                        .then(response => {
                            criteria.value = response.data;
                        })
                        .catch(() => {
                            toast.add({
                                severity: 'error',
                                summary: 'Thất bại',
                                detail: 'Không thể tải dữ liệu.',
                                life: 1000
                            });
                        })
                        .finally(() => {
                        });
                })
                .catch(() => {
                    toast.add({
                        severity: 'error',
                        summary: 'Thất bại',
                        detail: 'Không thể tải thông tin người được đánh giá.',
                        life: 1000
                    });
                })
                .finally(() => {
                });

            const checkValueDate = (st) => {
                return moment.unix(st/1000).toDate();
            };

            const timeEval = () => {
                    return moment(moment.unix(props.time/1000).toDate()).format('MM/YYYY');
            }

            EvaluationRepository.getEvaluationById(props.id, props.time/1000)
                .then(response => {
                    success.value = true;
                    evalInfo.value = response.data;
                    result.value = evalInfo.value.result;
                    note.value = evalInfo.value.note;
                })
                .catch(() => {
                })
                .finally(() => {
                });
            const confirmEval = () => {
                evaldetail.value.result = result.value;
                evaldetail.value.usereval = store.state.fullName;
                evaldetail.value.userid = useInfo.value.user_id;
                evaldetail.value.dateeval = checkValueDate(props.time);
                evaldetail.value.note = note.value;
                EvaluationRepository.evaluationEmployee(evaldetail.value)
                    .then(response => {
                        confirm.value = true;
                        toast.add({
                            severity: 'success',
                            summary: 'Thành công',
                            detail: 'Đánh giá thành công.',
                            life: 1000
                        });
                    })
                    .catch(() => {
                        toast.add({
                            severity: 'error',
                            summary: 'Thất bại',
                            detail: 'Không thể đánh giá.',
                            life: 1000
                        });
                    })
                    .finally(() => {
                    });
            };


            return {
                confirmEval,
                criteria,
                result,
                useInfo,
                success,
                timeEval,
                evalInfo,
                confirm,
                evaldetail,
                note,
            }
        }
    }
