
import LoginPage from "@/pages/LoginPage.vue";
import MainPage from "@/pages/MainPage.vue";

import { useStore } from 'vuex'
import { computed } from 'vue'
import ResultPage from "@/pages/ResultPage.vue";

export default {
  components: {
    // LoginPage,
    MainPage,
    ResultPage,
  },
  setup(){
    const store = useStore();
    const isLoggedIn = computed(() => !!store.state.token);

    return {
      isLoggedIn
    }
  }
}
