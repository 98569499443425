

import { computed, ref } from 'vue'
import { useToast } from "primevue/usetoast";
import { useRouter } from 'vue-router'
import {useStore} from "vuex";
import EmailRepository from "@/services/EmailRepository";
import {EmailDetail} from "@/models/emailDetail";

export default {

  setup(){
    const router = useRouter();
    const toast = useToast();
    const store = useStore();
    const emailDetail = ref({} as EmailDetail);

    const valid = () => {
      return emailDetail.value.name && emailDetail.value.email;
    };

    // if(!(store.state.permission == 'Admin')){
    //   router.push({
    //     name: 'home'
    //   });
    // }
    if(!(store.state.emailPermission == 'yes')){
      router.push({
        name: 'home'
      });
    }

    const doRegister = () => {
      EmailRepository.createEmail(emailDetail.value)
        .then((response) => {
          //teams.value = response.data;
          toast.add({
            severity: 'success',
            summary: 'Thành công',
            detail:'Địa chỉ email đã được thêm vào danh sách',
            life: 2000
          });
          emailDetail.value.email = "";
          emailDetail.value.name = "";
        })
        .catch(err => {
          toast.add({
            severity: 'error',
            summary: 'Lỗi',
            detail:err.response.data,
            life: 2000
          });
        });
    }

    return {
      doRegister,
      emailDetail,
      valid,
    }
  }
}

