
    import {ref} from 'vue'
    import {useToast} from "primevue/usetoast";
    import {Criteria} from "@/models/criteria.models";
    import {EvaluationCriteria} from "@/models/evaluationCriteria.models";
    import EvaluationCriteriaRepository from "@/services/EvaluationCriteriaRepository";

    export default {
        setup() {
            const criteria = ref([] as Criteria[]);
            const toast = useToast();
            const result = ref("");
            const evalCriteria = ref({} as EvaluationCriteria);

            const addEval = () => {
                EvaluationCriteriaRepository.create(evalCriteria.value)
                    .then((response) => {
                        console.log("#########$$$$$$$$$$$$$$$$$$%%%%%%%%%%%% response: " + JSON.stringify(response.data));
                        toast.add({
                            severity: 'success',
                            summary: 'Thành công',
                            detail:'Thêm quy chuẩn đánh giá thành công',
                            life: 2500
                        })
                    })
                    .catch()
                    .finally(
                        //function(){
                        //setTimeout(loadList, 1 * 1000);}
                    );
            };

            const deleteall = () => {
                evalCriteria.value = ({} as EvaluationCriteria);
            }

            return {
                evalCriteria,
                addEval,
                deleteall,
            }
        }
    }
