

import { computed, ref , reactive} from 'vue';
import {EmailDetail} from "@/models/emailDetail";
import {VideoDetail} from "@/models/videoDetail";
import EvaluationRepository from "@/services/EvaluationRepository";

export default {

  setup(){
    const emailDetail = ref({} as EmailDetail);
    const link = ref("https://192.168.1.178:8435/default.mp4");

    const valid = () => {
      return emailDetail.value.name && emailDetail.value.email;
    };
    const listVideo = ref([] as VideoDetail[]);

      EvaluationRepository.getListVideoDetail()
          .then((response) => {
              listVideo.value = response.data;
              console.log("###################@@@@@@@@@@@@@@@@@ EvaluationRepository: " + JSON.stringify(listVideo.value));
          })
          .catch(err => {
          });

      const PlayVideo = (id: number) => {
           listVideo.value.filter(x => {if(x.id == id) link.value = x.link;});
            console.log("$################################# PlayVideo list: " + listVideo.value.filter(x => x.id == id));
            console.log("$################################# PlayVideo id: " + id);
            console.log("$################################# PlayVideo link: " + link.value);
      }
    return {
      emailDetail,
      valid,
        listVideo,
      link,
        PlayVideo,
        options: { quality: { default: '1080p' } }
    }
  }
}

