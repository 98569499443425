

import {onMounted, ref} from 'vue'
import {useToast} from "primevue/usetoast";

import {useStore} from "vuex";
import {Team} from "@/models/team.models";
import TeamRepository from "@/services/TeamRepository";
import EvaluationRepository from "@/services/EvaluationRepository";
import router from "@/router";
import {useRouter} from "vue-router";
import {MedicalIncidentInfo} from "@/models/medicalIncidentInfo.models";
import axios from "axios";
import error from "devextreme/file_management/error";
import {FilterMatchMode} from "primevue/api";
import moment from "moment";

export default {
  setup() {
    const date = ref(new Date());
    const teams = ref({} as Team[]);
    const selectTeam = ref("all");
    const listMedical = ref([] as MedicalIncidentInfo[]);
    const idSelect = ref(0);
    const fileName = ref("");
    const downLoad = ref(false);
      const loadingBar = ref(false);
      const toast = useToast();
      TeamRepository.getTeams()
          .then((response) => {
              teams.value = response.data;
          })
          .catch();

    const loadData = () => {
        downLoad.value = false;
        console.log("#######$$$$$$$$$%%%%%%%%%%% getFileName selectTeam: " + selectTeam.value);
        if(selectTeam.value == null) selectTeam.value = "all";
        if((date.value[0] != null)&&(date.value[1] != null)){
            EvaluationRepository.getListMedical(date.value[0]/1000, date.value[1]/1000, selectTeam.value)
                .then((response) => {
                    listMedical.value = response.data;
                })
                .catch(err => {
                  listMedical.value = [];
                  toast.add({
                    severity: 'error',
                    summary: 'Lỗi',
                    detail:err.response.data,
                    life: 2500
                  })});
        }
    }
    const exportFile = (id: number) => {
        loadingBar.value = true;
        downLoad.value = false;
        EvaluationRepository.getFileName(id)
            .then((response) => {
                fileName.value = response.data;
                downLoad.value = true;
                loadingBar.value = false;

              // printer('api/eval/download/'+fileName.value);
              downloadFile();
            })
            .catch(error => {
                loadingBar.value = false;
            });
    };
      const downloadFile = () => {
          axios({
              url: 'api/eval/download/'+fileName.value,
              method: 'GET',
              responseType: 'blob',
          }).then((response) => {
              var fileURL = window.URL.createObjectURL(new Blob([response.data], {type: 'application/pdf'}));
              var fileLink = document.createElement('a');


              fileLink.href = fileURL;
              fileLink.setAttribute('download', "Bao_Cao_Su_Co_Y_Khoa.pdf");
              document.body.appendChild(fileLink);
              fileLink.click();
          })
              .catch(err => {
                  toast.add({
                      severity: 'error',
                      summary: 'Lỗi',
                      detail:err.response.data,
                      life: 2500
                  })});
      };

    const printFile = (id: number) => {
      loadingBar.value = true;
      downLoad.value = false;
      EvaluationRepository.getFileName(id)
          .then((response) => {
            fileName.value = response.data;
            downLoad.value = true;
            loadingBar.value = false;
           print();
          })
          .catch(error => {
            loadingBar.value = false;
          });
    };
    const print = () => {
      axios({
        url: 'api/eval/download/'+fileName.value,
        method: 'GET',
        responseType: 'blob',
      }).then((response) => {
        var fileURL = window.URL.createObjectURL(new Blob([response.data], {type: 'application/pdf'}));
        var fileLink = document.createElement('a');
        fileLink.href = fileURL;
        fileLink.setAttribute('download', "Bao_Cao_Su_Co_Y_Khoa.pdf");
        document.body.appendChild(fileLink);
        var myWindow = window.open(fileURL,"_blank");

        if(myWindow)
        {
          myWindow.print();
        }
      })
          .catch(err => {
            toast.add({
              severity: 'error',
              summary: 'Lỗi',
              detail:err.response.data,
              life: 2500
            })});
    };
      const valid = () => {
          return date.value[0] && date.value[1];
      }
    const filters = ref({
      'global': {value: null, matchMode: FilterMatchMode.CONTAINS}
    });

    const clearFilter = () => {
      initFilters();
    };

    const initFilters = () => {
      filters.value = {
        'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
      }
    };
    const formatDateTime = (date) => {
      return moment(String(date)).format('hh:mm DD/MM/YYYY');
    };

    const clearCalendar = () => {
      date.value = new Date();
      listMedical.value = [];
    }

    return {
        date,
        teams,
        selectTeam,
        loadData,
        listMedical,
        idSelect,
        exportFile,
        downloadFile,
        downLoad,
        valid,
        loadingBar,
      filters,
      clearFilter,
      initFilters,
      formatDateTime,
      clearCalendar,
      printFile,
    }
  }

}
