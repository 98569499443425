import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import LoginPage from '../pages/LoginPage.vue';
import RegisterPage from "@/pages/RegisterPage.vue";
import Home from "@/pages/Home.vue";
import ListEmployPage from "@/pages/ListEmployPage.vue";
import EvaluationPage from "@/pages/EvaluationPage.vue";
import ResultPage from "@/pages/ResultPage.vue";
import MedicalIncidentReport from "@/pages/MedicalIncidentReport.vue";
import SendEmail from "@/pages/SendEmail.vue";
import AddEmailPage from "@/pages/AddEmailPage.vue";
import StatisticalEmailPage from "@/pages/StatisticalEmailPage.vue";
import EvaluationCriteriaPage from "@/pages/EvaluationCriteriaPage.vue";
import EvaluationTeamPage from "@/pages/EvaluationTeamPage.vue";
import EvaluationTeamInfoPage from "@/pages/EvaluationTeamInfoPage.vue";
import MainPage from "@/pages/MainPage.vue";
import Directing from "@/pages/Directing.vue";
import DownloadPage from "@/pages/DownloadPage.vue";
import RegisterPatientPage from "@/pages/RegisterPatientPage.vue";
import EvaluationOfPatient from "@/pages/EvaluationOfPatient.vue";
import ListEvalPatient from "@/pages/ListEvalPatient.vue";
import VideoPage from "@/pages/VideoPage.vue";
import StatisticalEvalPatient from "@/pages/StatisticalEvalPatient.vue";
import UserList from "@/pages/UserList.vue";
import UserUpdatePage from "@/pages/UserUpdatePage.vue";
import StatisticalEvalTeamPage from "@/pages/StatisticalEvalTeamPage.vue";
import statisticalMedicalIncident from "@/pages/StatisticalMedicalIncident.vue";
import DetailEvalOfPatient from "@/pages/DetailEvalOfPatient.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: '/login',
    name: 'login',
    component: LoginPage,
    props: true
  },
  {
    path: '/register',
    name: 'register',
    component: RegisterPage,
    props: true
  },
  {
    path: '/listemploy',
    name: 'listemploy',
    component: ListEmployPage,
    props: true
  },
  {
    path: '/evaluation/:id/:time',
    name: 'evaluation',
    component: EvaluationPage,
    props: true
  },
  {
    path: '/result',
    name: 'result',
    component: ResultPage,
    props: true
  },
  {
    path: '/home',
    name: 'home',
    component: Home,
    props: true
  },
  {
    path: '/medicalincident',
    name: 'medicalincident',
    component: MedicalIncidentReport,
    props: true
  },
  {
    path: '/sendemail',
    name: 'sendemail',
    component: SendEmail,
    props: true
  },
  {
    path: '/addemail',
    name: 'addemail',
    component: AddEmailPage,
  },
  {
    path: '/statisticalemailpage',
    name: 'statisticalemailpage',
    component: StatisticalEmailPage,
  },
  {
    path: '/evalcriteria',
    name: 'evalcriteria',
    component: EvaluationCriteriaPage,
  },
  {
    path: '/evalteam',
    name: 'evalteam',
    component: EvaluationTeamPage,
  },
  {
    path: '/evalteaminfo',
    name: 'evalteaminfo',
    component: EvaluationTeamInfoPage,
  },
  {
    path: '/mainpage',
    name: 'mainpage',
    component: MainPage,
  },
  {
    path: '/directing',
    name: 'directing',
    component: Directing,
  },
  {
    path: '/download',
    name: 'download',
    component: DownloadPage,
  },
  {
    path: '/registerpatient',
    name: 'registerpatient',
    component: RegisterPatientPage,
  },
  {
    path: '/evalofpatient',
    name: 'evalofpatient',
    component: EvaluationOfPatient,
  },
  {
    path: '/listevalpatient',
    name: 'listevalpatient',
    component: ListEvalPatient,
  },
  {
    path: '/videopage',
    name: 'videopage',
    component: VideoPage,
  },
  {
    path: '/statisticalevalpatient',
    name: 'statisticalevalpatient',
    component: StatisticalEvalPatient,
  },
  {
    path: '/userlistinfo',
    name: 'userlistinfo',
    component: UserList,
  },
  {
    path: '/userupdate/:id',
    name: 'userupdate',
    component: UserUpdatePage,
    props: true
  },
  {
    path: '/statisticalevalteam',
    name: 'statisticalevalteam',
    component: StatisticalEvalTeamPage,
  },{

    path: '/statisticalmedicalincident',
    name: 'statisticalmedicalincident',
    component: statisticalMedicalIncident,
  },
  {
    path: '/detailevalofpatient/:id',
    name: 'detailevalofpatient',
    component: DetailEvalOfPatient,
    props: true
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
