
import {computed, onMounted, ref} from 'vue';
    import {useToast} from "primevue/usetoast";
    import {FileDetail} from "@/models/fileDetail.models";
    import EmailRepository from "@/services/EmailRepository";
    import axios from "axios";
    import {DirectingForm} from "@/models/directingForm.models";
    import {AddInfo} from "@/models/addInfo.models";
    import error from "devextreme/file_management/error";
    import {useConfirm} from "primevue/useconfirm";

    export default {
        setup() {
            const quanly = ref(0);
            const hopDong = ref(true);
            const showDetail = ref(false);
            let listFileName = ref([] as FileDetail[]);
            const listLink = ref([] as string[]);
            const directingForm = ref({} as DirectingForm);
            const toast = useToast();
            const list = ref([] as AddInfo[]);
            const loadingBar = ref(false);

            const selectContact = (x: number) =>
            {
              reset();
                directingForm.value.loaicd = x;
                showDetail.value = true;
                if(x == 2)
                {
                    hopDong.value = true;
                }
                else
                {
                    hopDong.value = false;
                }
            };
            const fileChange = (fileList) => {
                var leng = fileList.length;
                for (var i = 0; i < leng; i++)
                {
                    var filetemp = ref({} as FileDetail);
                    filetemp.value.nameFile = fileList[i].name;
                    filetemp.value.value = fileList[i];
                    listFileName.value.push(filetemp.value);
                }
            };
            const upload = () => {
                {
                    listLink.value = [];
                    directingForm.value.thongTinNguoiCG = list.value;
                    var files = new FormData();
                    listFileName.value.forEach(x =>
                    {
                        files.append("formFiles", x.value, x.nameFile);
                    });
                    loadingBar.value = true;
                    //loadingBar.value = true;
                    //downLoad.value = false;
                    console.log("###########@@@@@@@@@@@$$$$$$$$$$$$$$$$$$$$ upload files: " + JSON.stringify(files));
                    axios.post(`/api/file/Upload`, files,
                        {
                            headers: {
                                Accept: 'application/json',
                                'Content-Type': 'multipart/form-data'
                            }
                        }).then(response => {
                        directingForm.value.files = listLink.value = response.data.result;

                        EmailRepository.createDirecting(directingForm.value)
                            .then((response) => {
                                loadingBar.value = false;
                                confirmSuccess();
                            })
                            .catch(error => {
                                loadingBar.value = false;
                            })
                            .finally(
                                //function(){
                                //setTimeout(loadList, 1 * 1000);}
                            );
                    })
                        .catch(err => {
                            toast.add({
                                severity: 'error',
                                summary: 'Lỗi',
                                detail:err.response.data,
                                life: 2500
                            })});
                }
            };

            const reset = () => {
                var temp = ref({} as DirectingForm);
                const listtemp = ref([] as AddInfo[]);
              // const list = ref([] as AddInfo[]);
                directingForm.value = temp.value;
                list.value = [];
                list.value.push({} as AddInfo);
            }
            const confirm = useConfirm();
            const confirmSuccess = () => {
                confirm.require({
                    message: 'Thêm chỉ đạo tuyến thành công',
                    header: 'Xác nhận',
                    icon: 'pi pi-check-circle',
                    acceptLabel: 'Thêm chỉ đạo mới',
                    rejectLabel: 'Thêm tiếp',
                    accept: () => {
                        reset();
                    },
                    reject: () => {
                    }
                });
            };

            const deleteFile = (name: string) =>
            {
                listFileName.value.findIndex(x => x.nameFile == name);
                listFileName.value.splice(listFileName.value.findIndex(x => x.nameFile == name), 1);
            };
            const addInfo = () => {
                console.log("###########@@@@@@@@@@@$$$$$$$$$$$$$$$$$$$$ addInfo list: " + JSON.stringify(list.value));
                const add = ref({} as AddInfo);
                list.value.push(add.value);
            };
            const minusInfo = (minus: any) => {
              console.log("###########@@@@@@@@@@@$$$$$$$$$$$$$$$$$$$$ addInfo minus: " + JSON.stringify(minus));
              // list.value.findIndex(x => x.name == minus.name)
              list.value.splice(list.value.findIndex(x => x.name == minus.name), 1);
            };
            addInfo();

          const valid = computed(()=> (directingForm.value.tenkt == null) || (directingForm.value.noidungcg == null) || (directingForm.value.tenkt == "") || (directingForm.value.noidungcg == ""));

            return {
                quanly,
                hopDong,
                selectContact,
                showDetail,
                fileChange,
                upload,
                deleteFile,
                listFileName,
                list,
                addInfo,
                directingForm,
                loadingBar,
                confirmSuccess,
                minusInfo,
                valid
            }
        }

    }
