

import { computed, ref } from 'vue'
import AuthRepository from '@/services/AuthRepository'
import { useToast } from "primevue/usetoast";
import { useRouter } from 'vue-router'
import {Register} from "../models/register";
import {useStore} from "vuex";
import TeamRepository from "../services/TeamRepository";
import {Team} from "@/models/team.models";
import {UserPermission} from "@/models/permission.models";
import {DanhSachBenhNhan} from "@/models/danhsachbenhnhan.models";
import axios from "axios";
import PatientRepository from "@/services/PatientRepository";

export default {

  setup(){
    const router = useRouter();
    const register = ref({} as Register);
    const toast = useToast();
    const store = useStore();
    const error = ref(null);
    const list = ref([] as DanhSachBenhNhan[]);
    const benhnhan_id = ref(0);

    const valid = computed(()=> register.value.password && register.value.username && register.value.team && register.value.fullname && register.value.address);

    // const loadData = computed(() =>
    // {

        axios.get(`http://279e-113-160-225-141.ngrok.io/api/benhnhan/danhsachbenhnhan`).then((response) => {
            list.value = response.data;
            console.log("################################## getlist benh nhan: " + JSON.stringify(response.data));
        })
            .catch(err => {
                toast.add({
                    severity: 'error',
                    summary: 'Lỗi',
                    detail:err.response.data,
                    life: 2500
                })});
  //   }
  // );
    // loadData;
      // PatientRepository.getList()
      //     .then((response) => {
      //         //teams.value = response.data;
      //         list.value = response.data;
      //         console.log("################################## getlist benh nhan: " + JSON.stringify(response.data));
      //     })
      //     .catch(err => {
      //         toast.add({
      //             severity: 'error',
      //             summary: 'Lỗi',
      //             detail:err.response.data,
      //             life: 2000
      //         });
      //     });


    return {
      register,
      valid,
      error,
        benhnhan_id,
        list
    }
  }
}

