

import {computed, onMounted, ref} from 'vue'
import {useToast} from "primevue/usetoast";

import error from "devextreme/file_management/error";
import {FilterMatchMode} from "primevue/api";
import moment from "moment";
import {EvalTeamCount} from "@/models/evalTeamCount.models";
import EvaluationCriteriaRepository from "@/services/EvaluationCriteriaRepository";


export default {
  setup() {
      const toast = useToast();
      const evalTeamCount = ref([] as EvalTeamCount[]);
      const date = ref("");


    const loadData = (year: string) => {
      EvaluationCriteriaRepository.getListEvalTeamCount(year)
          .then((response) => {
            evalTeamCount.value = response.data;
            const countValue = ref(0);
            const count = ref(0);
            evalTeamCount.value.forEach(x => {
              count.value++;
              countValue.value = countValue.value + x.five_star + x.four_star + x.three_star + x.two_star + x.one_star;
            });

            if(countValue.value == 0)
            {
              toast.add({
                severity: 'error',
                summary: 'Lỗi',
                detail:'Không có dữ liệu đánh giá, vui lòng chọn lại thời gian',
                life: 3000
              });
            }
          })
          .catch(err => {
            toast.add({
              severity: 'error',
              summary: 'Lỗi',
              detail:err.response.data,
              life: 2000
            });
          });
    }
    // loadData("2020");
    const totalDataInQuarter1 = computed(() => {
      const data = ref({} as EvalTeamCount);
      evalTeamCount.value.forEach(x => {if(x.quarter == 1) data.value = x});
      return {
        labels: ['Đánh giá 5*','Đánh giá 4*','Đánh giá 3*','Đánh giá 2*','Đánh giá 1*'],
        datasets: [
          {
            label: 'Đánh giá chất lượng bệnh viện',
            data: [data.value.five_star, data.value.four_star,
              data.value.three_star, data.value.two_star, data.value.one_star],
            backgroundColor: ["#36A2EB","#56d9d9","#69eb36","#e4f640","#FF6384"],
            hoverBackgroundColor: ["#36A2EB","#56d9d9","#69eb36","#e4f640","#FF6384"],
          },
        ]
      }
    });
    const totalDataInQuarter2 = computed(() => {
      const data = ref({} as EvalTeamCount);
      evalTeamCount.value.forEach(x => {if(x.quarter == 2) data.value = x});
      return {
        labels: ['Đánh giá 5*','Đánh giá 4*','Đánh giá 3*','Đánh giá 2*','Đánh giá 1*'],
        datasets: [
          {
            label: 'Đánh giá chất lượng bệnh viện',
            data: [data.value.five_star, data.value.four_star,
              data.value.three_star, data.value.two_star, data.value.one_star],
            backgroundColor: ["#36A2EB","#56d9d9","#69eb36","#e4f640","#FF6384"],
            hoverBackgroundColor: ["#36A2EB","#56d9d9","#69eb36","#e4f640","#FF6384"],
          },
        ]
      }
    });
    const totalDataInQuarter3 = computed(() => {
      const data = ref({} as EvalTeamCount);
      evalTeamCount.value.forEach(x => {if(x.quarter == 3) data.value = x});
      return {
        labels: ['Đánh giá 5*','Đánh giá 4*','Đánh giá 3*','Đánh giá 2*','Đánh giá 1*'],
        datasets: [
          {
            label: 'Đánh giá chất lượng bệnh viện',
            data: [data.value.five_star, data.value.four_star,
              data.value.three_star, data.value.two_star, data.value.one_star],
            backgroundColor: ["#36A2EB","#56d9d9","#69eb36","#e4f640","#FF6384"],
            hoverBackgroundColor: ["#36A2EB","#56d9d9","#69eb36","#e4f640","#FF6384"],
          },
        ]
      }
    });
    const totalDataInQuarter4 = computed(() => {
      const data = ref({} as EvalTeamCount);
      evalTeamCount.value.forEach(x => {if(x.quarter == 4) data.value = x});
      return {
        labels: ['Đánh giá 5*','Đánh giá 4*','Đánh giá 3*','Đánh giá 2*','Đánh giá 1*'],
        datasets: [
          {
            label: 'Đánh giá chất lượng bệnh viện',
            data: [data.value.five_star, data.value.four_star,
              data.value.three_star, data.value.two_star, data.value.one_star],
            backgroundColor: ["#36A2EB","#56d9d9","#69eb36","#e4f640","#FF6384"],
            hoverBackgroundColor: ["#36A2EB","#56d9d9","#69eb36","#e4f640","#FF6384"],
          },
        ]
      }
    });

    const timeConvert = () => {
      return moment(date.value).format('YYYY');
    }

    const selectDate = () => {
      loadData(timeConvert().toString());
    }

    return {
      totalDataInQuarter1,
      totalDataInQuarter2,
      totalDataInQuarter3,
      totalDataInQuarter4,
      date,
      selectDate,
    }
  }

}
