
    import {ref} from 'vue'
    import {useToast} from "primevue/usetoast";
    import moment from 'moment';
    import {useStore} from "vuex";
    import {Criteria} from "@/models/criteria.models";
    import EvaluationRepository from "@/services/EvaluationRepository";
    import {UserInfo} from "@/models/userInfo.models";
    import {UserInfoDetail} from "@/models/userInfoDetail.models";
    import {EvaluationDetail} from "@/models/evaluationDetail.models";
    import {EvaluationInfo} from "@/models/evaluationInfo.models";
    import {Time} from "@/models/time.models";

    export default {
        setup(props) {
            const toast = useToast();
            const result = ref("");
            const date = ref({} as Time);
            const evalInfo = ref({} as EvaluationInfo);
            const check = ref(false);

            const loadInfo = () => {

                EvaluationRepository.getEvaluation(date.value.monthSelect.getTime()/1000)
                    .then((response) => {
                        check.value = true;
                        evalInfo.value = response.data;
                    })
                    .catch(err => {
                        check.value = false;
                        toast.add({
                            severity: 'error',
                            summary: 'Lỗi',
                            detail:err.response.data,
                            life: 2500
                        })});
            };

            return {
                loadInfo,
                result,
                date,
                evalInfo,
                check,
            }
        }
    }
