

import {onMounted, ref} from 'vue'
import {useToast} from "primevue/usetoast";

import {useStore} from "vuex";
import {Team} from "@/models/team.models";
import TeamRepository from "@/services/TeamRepository";
import {FilterMatchMode} from "primevue/api";
import EvaluationRepository from "@/services/EvaluationRepository";
import {useConfirm} from "primevue/useconfirm";
import {UserInfo} from "@/models/userInfo.models";
import router from "@/router";
import {useRouter} from "vue-router";
import {Time} from "@/models/time.models";
import jsPDF from 'jspdf';
import moment from "moment";
import font from "@/utils/font";
import autoTable from "jspdf-autotable";

export default {
  setup() {
    const date = ref({} as Time);
    const edit= ref(false);
    const teams = ref({} as Team[]);
    const checkbox = ref(false);
    const listUser = ref([] as UserInfo[]);
    const listUserTemp = ref([] as UserInfo[]);
    const selectStatus = ref(0);
      const permission = ref([
          { permission_name: 'Trưởng phòng/Trưởng khoa', value: 'Leader'},
          { permission_name: 'Nhân viên', value: 'Employee'},
      ]);
      const store = useStore();
      const permissionSelect = ref("");
    const selectTeam = ref("");
    const router = useRouter();
    const checkPermission = ref(false);

      if((store.state.permission == 'Admin')||(store.state.permission == 'Director')){
          checkPermission.value = true;
      }

      const loadList = () => {
              EvaluationRepository.getList(date.value.monthSelect.getTime()/1000)
                  .then((response) => {
                      listUserTemp.value = listUser.value = response.data.filter(x => x.full_name != store.state.fullName);
                  })
                  .catch()
                  .finally(
                      //function(){
                      //setTimeout(loadList, 1 * 1000);}
      );
      };
      //loadList();
    const filters = ref({
      'global': {value: null, matchMode: FilterMatchMode.CONTAINS}
    });

    const clearFilter = () => {
      initFilters();
    };

    const initFilters = () => {
      filters.value = {
        'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
      }
    };

    const selectPermission = () => {
        if(permissionSelect.value != "" && permissionSelect.value != null)
        {
            listUser.value = listUserTemp.value.filter(x => x.permission == permissionSelect.value);
        }
        else
        {
            listUser.value = listUserTemp.value;
        }
    }

    const evaluation = (id: any) => {
        let routeData = router.resolve({name: 'evaluation', params: {id: id,time: date.value.monthSelect.getTime()}});
        window.open(routeData.href, '_blank');
    };

    const checkStatus = (st) => {
        if(st == "Chưa đánh giá") return false;
        else return  true;
    }

      const checkValueMonth = (st) => {
          return moment.unix(st/1000).format('MM/YYYY');
      };
    //onMounted(loadBook);
      const createPDF = () => {
          const doc = new jsPDF('l', 'px', 'a4');
          let data :any[] = [];
          const count = ref(0);
          listUser.value.forEach(x => {
              count.value++;
              let item = [count.value, x.full_name, x.status, x.eval];
              data.push(item);
          });
          const TimesNewRoman = font();

          doc.addFileToVFS("Fontvn.ttf", TimesNewRoman);
          doc.addFont("Fontvn.ttf", "Fontvn", "normal");
          doc.setFont("Fontvn");

          doc.text('DANH SÁCH KHAI ĐÁNH GIÁ NHÂN VIÊN: TRONG THỜI GIAN ' + checkValueMonth(date.value.monthSelect.getTime()) , 320, 20,  { align: 'center' });
          autoTable(doc, {styles: {font: "Fontvn", fontSize: 13,},
              headStyles: {fillColor: [65, 127, 181]},
              theme: 'grid',
              head: [["STT", "Họ và tên", "Tình trạng", "Xếp Loại"]],
              body: data,},)
          doc.save('DanhGia.pdf');
      };

    return {
        checkPermission,
        checkStatus,
        date,
        permission,
        evaluation,
        loadList,
        edit,
        filters,
        clearFilter,
        initFilters,
        checkbox,
        permissionSelect,
        selectStatus,
        selectPermission,
        teams,
        selectTeam,
        listUser,
        createPDF,
    }
  }

}
