

import {computed, onMounted, ref} from 'vue'
import {useToast} from "primevue/usetoast";

import {useStore} from "vuex";
import EvaluationRepository from "@/services/EvaluationRepository";
import {useRouter} from "vue-router";
import {EvalPatientCount} from "@/models/evalPatientCount.models";

export default {
  setup() {
    const store = useStore();
    const date = ref();
    const date2 = ref();
    const router = useRouter();
    const listEvalPatient = ref([] as EvalPatientCount[]);
    const EvalPatientInMonth = ref([] as EvalPatientCount[]);
    const EvalPatientInYear = ref({} as EvalPatientCount);
    const listEvalInPatient = ref([] as EvalPatientCount[]);
    const listEvalOutPatient = ref([] as EvalPatientCount[]);
    const toast = useToast();
    const showStatistical = ref(false);
    const checkDataMonth = ref(false);
    const checkDataYear = ref(false);
    const checkListInPatient = ref(false);
    const checkListOutPatient = ref(false);

    const selectCalendar = () => {
      if((date.value != null)&&(date2.value != null)) {
        EvaluationRepository.getListPatientCount(date.value/1000, date2.value/1000)
            .then((response) => {
              listEvalPatient.value = response.data;
              showStatistical.value = true;
            })
            .catch(err => {
              toast.add({
                severity: 'error',
                summary: 'Lỗi',
                detail:err.response.data,
                life: 2000
              });
            });
      }
    };
      EvaluationRepository.getListPatientCountInMonth()
          .then((response) => {
            console.log("#################### debug data log selectCalendarInMonth EvalPatientInMonth: " + JSON.stringify(response.data));
            EvalPatientInMonth.value = response.data;
            checkDataMonth.value = true;
          })
          .catch(err => {
            toast.add({
              severity: 'error',
              summary: 'Lỗi',
              detail:err.response.data,
              life: 2000
            });
          });

      EvaluationRepository.getPatientCountInYear()
          .then((response) => {
            EvalPatientInYear.value = response.data;
            checkDataYear.value = true;
          })
          .catch(err => {
            toast.add({
              severity: 'error',
              summary: 'Lỗi',
              detail:err.response.data,
              life: 2000
            });
          });

    EvaluationRepository.getListPatientCountInYear(1)
        .then((response) => {
          listEvalInPatient.value = response.data;
          checkListInPatient.value = true;
        })
        .catch(err => {
          toast.add({
            severity: 'error',
            summary: 'Lỗi',
            detail:err.response.data,
            life: 2000
          });
        });
    EvaluationRepository.getListPatientCountInYear(2)
        .then((response) => {
          listEvalOutPatient.value = response.data;
          checkListOutPatient.value = true;
        })
        .catch(err => {
          toast.add({
            severity: 'error',
            summary: 'Lỗi',
            detail:err.response.data,
            life: 2000
          });
        });

    const totalDataInMonth = computed(() => {
      return {
        labels: ['Đánh giá dưới trung bình','Đánh giá trên trung bình','Đánh giá tốt'],
        datasets: [
          {
            label: 'Đánh giá của bệnh nhân',
            data: [EvalPatientInMonth.value[0].duoitb, EvalPatientInMonth.value[0].trentb, EvalPatientInMonth.value[0].tot],
            backgroundColor: ["#FF6384","#FFCE56","#36A2EB"],
            hoverBackgroundColor: ["#FF6384","#FFCE56","#36A2EB"],
          },
        ]
      }
    });
    const totalDataInYear = computed(() => {
      return {
        labels: ['Đánh giá dưới trung bình','Đánh giá trên trung bình','Đánh giá tốt'],
        datasets: [
          {
            label: 'Đánh giá của bệnh nhân',
            data: [EvalPatientInYear.value.duoitb, EvalPatientInYear.value.trentb, EvalPatientInYear.value.tot],
            backgroundColor: ["#FF6384","#FFCE56","#36A2EB"],
            hoverBackgroundColor: ["#FF6384","#FFCE56","#36A2EB"],
          },
        ]
      }
    });
    const totalListInPatient = computed(() => {
      const data = listEvalInPatient.value;

      return {
        labels: data.map(x => x.thang),
        datasets: [
          {
            label: 'Đánh giá dưới trung bình',
            data: data.map(x => x.duoitb),
            fill: false,
            borderColor: '#FF6384',
            borderWidth: 2,
          },
          {
            label: 'Đánh giá trên trung bình',
            data: data.map(x => x.trentb),
            fill: false,
            borderColor: '#FFCE56',
            borderWidth: 2,
          },
          {
            label: 'Đánh giá tốt',
            data: data.map(x => x.tot),
            fill: false,
            borderColor: '#36A2EB',
            borderWidth: 2,
          }
        ]
      }
    });

    const totalListOutPatient = computed(() => {
      const data = listEvalOutPatient.value;

      return {
        labels: data.map(x => x.thang),
        datasets: [
          {
            label: 'Đánh giá dưới trung bình',
            data: data.map(x => x.duoitb),
            fill: false,
            borderColor: '#fd486f',
            borderWidth: 2,
          },
          {
            label: 'Đánh giá trên trung bình',
            data: data.map(x => x.trentb),
            fill: false,
            borderColor: '#ffcc4f',
            borderWidth: 2,
          },
          {
            label: 'Đánh giá tốt',
            data: data.map(x => x.tot),
            fill: false,
            borderColor: '#1a99ef',
            borderWidth: 2,
          }
        ]
      }
    });

    const totalData = computed(() => {
      const data = listEvalPatient.value;

      return {
        labels: data.map(x => x.thang),
        datasets: [
          {
            label: 'Đánh giá dưới trung bình',
            data: data.map(x => x.duoitb),
            fill: false,
            borderColor: '#F54266FF',
          },
          {
            label: 'Đánh giá trên trung bình',
            data: data.map(x => x.trentb),
            fill: false,
            borderColor: '#fff761',
          },
          {
            label: 'Đánh giá tốt',
            data: data.map(x => x.tot),
            fill: false,
            borderColor: '#01d7fd',
          }
        ]
      }
    });
    const basicOptions = ref(
        {
          plugins: {
            legend: {
              labels: {
                color: '#495057'
              }
            }
          },
          scales: {
            x: {
              ticks: {
                color: '#495057'
              },
              grid: {
                color: '#ebedef'
              }
            },
            y: {
              ticks: {
                color: '#495057'
              },
              grid: {
                color: '#ebedef'
              }
            }
          }
        }
    );
    const lightOptions = ref({
      plugins: {
        legend: {
          labels: {
            color: '#495057'
          }
        }
      }
    });

    return {
      showStatistical,
      date,
      selectCalendar,
      listEvalPatient,
      totalData,
      date2,
      totalDataInYear,
      totalDataInMonth,
      EvalPatientInMonth,
      EvalPatientInYear,
      checkDataMonth,
      checkDataYear,
      checkListInPatient,
      totalListInPatient,
      checkListOutPatient,
      totalListOutPatient,
      basicOptions,
      lightOptions,
    }
  }

}
