

import { Login } from '../models/login'
import { computed} from 'vue'
import { ref } from 'vue'
import AuthRepository from '../services/AuthRepository'
import { useStore } from 'vuex'
import { useToast } from 'primevue/usetoast'
import { useRouter } from 'vue-router'

export default {

  setup (props) {
    const router = useRouter();
    const login = ref({} as Login);
    const store = useStore();
    const toast = useToast();
    const userName = ref("");
    const password = ref("");

    const valid = computed(() => password.value && userName.value)

    const doLogin = () => {
        var md5 = require("md5");
      console.log("####################@@@@@@@@@@@@@@@@@@@@@ userPermission auth userName.value: " + userName.value);
        login.value.username = userName.value;
        login.value.password = md5(password.value);
      console.log("####################@@@@@@@@@@@@@@@@@@@@@ userPermission auth login.value: " + JSON.stringify(login.value));
      AuthRepository.auth(login.value)
        .then((response) => {
          console.log("####################@@@@@@@@@@@@@@@@@@@@@ userPermission auth aaa: ");
          store.dispatch('setToken', response.data.token)
          toast.add({
            severity: 'success',
            summary: 'Đăng nhập',
            detail: 'Đăng nhập thành công vào hệ thống',
            life: 1000
          });
          AuthRepository.userPermission(login.value)
                  .then((responses)=>{
                    store.dispatch('setPermission', responses.data.permission);
                    store.dispatch('setTeam', responses.data.team);
                    store.dispatch('setFullName', responses.data.fullname);
                    store.dispatch('setEmailPermission', responses.data.email_permission);
                    console.log("####################@@@@@@@@@@@@@@@@@@@@@ userPermission responses: " + JSON.stringify(responses.data));
                  })
                  .catch(() => {
                  });
          router.push({
            name: 'home'
          });
        })
        .catch(() => {
          toast.add({
            severity: 'error',
            summary: 'Đăng nhập',
            detail: 'Đăng nhập thất bại. Vui lòng kiểm tra lại thông tin đăng nhập',
            life: 1000
          })
        })
    }

    return {
      login,
      doLogin,
      valid,
      password,
      userName,
    }
  }
}

