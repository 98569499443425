

import {onMounted, ref} from 'vue';
import { computed} from 'vue'
import {EvalTitle} from "@/models/evalTitle.models";
import AuthRepository from "@/services/AuthRepository";
import {EvalPatient} from "@/models/evalPatient";
import EvaluationRepository from "@/services/EvaluationRepository";
import {useConfirm} from "primevue/useconfirm";
import {useToast} from "primevue/usetoast";

export default {
  props: {
    id: Number,
  },
  setup(props) {
      const evals = ref([{ "label": "Rất không hài lòng hoặc: Rất kém","label1": "Không hài lòng hoặc: Kém","label2": "Bình thường hoặc: Trung bình","label3": "Hài lòng hoặc: Tốt","label4": "Rất hài lòng hoặc: Rất tốt"}]);

      const listQues = ref([
          {label:'1. Chắc chắn không bao giờ quay lại', value: 1},
          {label:'2. Không muốn quay lại nhưng có ít lựa chọn khác', value: 2},
          {label:'3. Muốn chuyển tuyến sang bệnh viện khác', value: 3},
          {label:'4. Có thể sẽ quay lại', value: 4},
          {label:'5. Chắc chắn sẽ quay lại hoặc giới thiệu cho người khác', value: 5},
          {label:'Khác (ghi rõ) ...', value: 6},
      ]);
      const percent = ref();
      const listEval = ref([] as EvalTitle[]);
      const evalPatient = ref({} as EvalPatient);
      const danhgia = ref("");
      const diemso = ref(0);
      const toast = useToast();

      const loadData = (id: number) =>{
          listEval.value =[];
          if(id == 1)
              evalPatient.value.hinhthuckhambenh = "nội trú";
          else if(id == 2)
              evalPatient.value.hinhthuckhambenh = "ngoại trú";
          AuthRepository.listEval(id)
              .then((response) => {
                  listEval.value = response.data;
                splitString(evalPatient.value.danhgiacuthe);
                console.log("##################$$$$$$$$$$$$$$$$$$$$AAAAAAAAA evalClick listEval loadData: " + JSON.stringify(listEval.value));
              })
              .catch()
              .finally(
                  //function(){
                  //setTimeout(loadList, 1 * 1000);}
              );
      }

    EvaluationRepository.getEvalPatientById(props.id)
        .then((response) => {
          evalPatient.value = response.data;
          console.log("##################$$$$$$$$$$$$$$$$$$$$AAAAAAAAA evalClick listEval evalPatient: " + JSON.stringify(evalPatient.value));
          if(evalPatient.value.hinhthuckhambenh == "ngoại trú") {
            loadData(2);
          }
          else {
            loadData(1);
          }
        })
        .catch()
        .finally(
            //function(){
            //setTimeout(loadList, 1 * 1000);}
        );

      const reset = () => {
          const temp = ref({} as EvalPatient);
          listEval.value = [];
          evalPatient.value = temp.value;
          danhgia.value = "";
          diemso.value = 0;
      }
      const confirmSuccess = () => {
          reset();
          toast.add({
              severity: 'success',
              summary: 'Đánh giá thành công',
              detail: 'Cảm ơn quý khách đã đánh giá, hi vọng quý khách đã có trải nghiệm tốt tại bệnh viện, xin cảm ơn',
              life: 4000
          });
      };
    const splitString = (value: any) => {
      console.log("##################$$$$$$$$$$$$$$$$$$$$AAAAAAAAA splitString: " + value);
        const evalstring = ref([] as string[]);
        evalstring.value = value.split("|");
        console.log("##################$$$$$$$$$$$$$$$$$$$$AAAAAAAAA evalstring: " + JSON.stringify(evalstring.value));
        console.log("##################$$$$$$$$$$$$$$$$$$$$AAAAAAAAA listEval: " + JSON.stringify(listEval.value));
        evalstring.value.forEach(x => {
          const st = ref([] as string[]);
          st.value = x.split(" - ");
          // console.log("##################$$$$$$$$$$$$$$$$$$$$AAAAAAAAABBBB evalstring x: " + JSON.stringify(x));
          console.log("##################$$$$$$$$$$$$$$$$$$$$AAAAAAAAABBBB evalstring st: " + JSON.stringify(st.value));
          listEval.value.forEach(y => {
            y.listques.forEach(z => {
              console.log("##################$$$$$$$$$$$$$$$$$$$$AAAAAAAAA z.ma : " + z.ma + ",, st: " + st.value[1] );
              if(z.ma == st.value[0]) z.xeploai = parseInt(st.value[1]);
            });
          });
        })
    };
    const valid = computed(() => {
        return evalPatient.value.ten&&evalPatient.value.gioitinh&&evalPatient.value.dienthoai;
      });

    return {
        listEval,
        listQues,
        percent,
        evalPatient,
        loadData,
        evals,
        valid,
    }
  }

}
