import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate'

export default createStore({
  state: {
    token: '',
    permission: '',
    team: '',
    fullName:'',
    emailPermission:'',
  },
  getters: {
    isLoggedIn (state): boolean {
      return !!state.token
    },
    getPermission(state): string {
      return state.permission
    },
    getTeam(state): string {
      return state.team
    },
    getFullName(state): string {
      return state.fullName
    },
    getEmailPermission(state): string {
      console.log("############################ getEmailPermission: " + state.emailPermission);
      return state.emailPermission
    },
  },
  mutations: {
    setToken (state, token) {
      state.token = token
    },
    clearToken (state) {
      state.token = ''
    },
    setPermission(state, permission){
      state.permission = permission;
    },
    clearPermission(state){
      state.permission = '';
    },
    setTeam(state, team){
      state.team = team;
    },
    clearTeam(state){
      state.team = '';
    },
    setFullName(state, fullName){
      state.fullName = fullName;
    },
    clearFullName(state){
      state.fullName = '';
    },
    setEmailPermission(state, emailPermission){
      state.emailPermission = emailPermission;
      console.log("############################ setEmailPermission: " + state.emailPermission);
    },
    clearEmailPermission(state){
      state.emailPermission = '';
    },
  },
  actions: {
    setToken ({ commit }, token) {
      commit('setToken', token)
    },
    clearToken ({ commit }) {
      commit('clearToken')
    },
    setPermission({ commit }, permission){
      commit('setPermission', permission);
    },
    clearPermission({ commit }){
      commit('clearPermission');
    },
    setTeam({ commit }, team){
      commit('setTeam', team);
    },
    clearTeam({ commit }){
      commit('clearTeam');
    },
    setFullName({ commit }, fullName){
      commit('setFullName', fullName);
    },
    clearFullName({ commit }){
      commit('clearFullName');
    },
    setEmailPermission({ commit }, emailPermission){
      commit('setEmailPermission', emailPermission);
    },
    clearEmailPermission({ commit }){
      commit('clearEmailPermission');
    },
  },
  modules: {
  },
  plugins: [createPersistedState()]
})
