import Repository from './Repository'
import { AxiosResponse } from 'axios'
import { Login } from '../models/login'
import { TokenModel } from '../models/token.model'
import {Register} from "@/models/register";
import PasswordModel from "@/models/password.model";
import {UserPer} from "@/models/userPermission.model";
import {UserUpdate} from "@/models/userUpdate.model";

const resource = '/auth'

export default new class {
  auth (login: Login): Promise<AxiosResponse<TokenModel>> {
    console.log('############################## loginpage auth api')
    return Repository.post<TokenModel>(`${resource}/login`, login)
  }
  userPermission(login: Login): Promise<AxiosResponse<UserPer>>{
    return Repository.post<UserPer>(`${resource}/permission`, login);
  }
  registerUser(register: Register): Promise<any>{
    return Repository.post<any>(`${resource}/register`, register);
  }
  changePassword(changePassword: PasswordModel): Promise<any>{
    return Repository.post<TokenModel>(`${resource}/password`, changePassword);
  }
  listEval(kind: number):  Promise<AxiosResponse<any>>{
    return Repository.get<any>(`${resource}/listEval/${kind}`);
  }
  userListInfo(): Promise<AxiosResponse<any>>{
    return Repository.get<any>(`${resource}/getList`)
  }
  getUserById(id: number): Promise<AxiosResponse<any>>{
    return Repository.get<any>(`${resource}/getuserbyid/${id}`);
  }
  userUpdate(user: UserUpdate): Promise<AxiosResponse<any>> {
    return Repository.post<any>(`${resource}/update`, user);
  }
  deleteUser(id: number): Promise<AxiosResponse<any>>{
    return Repository.delete<any>(`${resource}/delete/${id}`);
  }
}
