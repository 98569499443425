import Repository from './Repository';
import {AxiosResponse} from "axios";
import {Team} from "../models/team.models";
import {TokenModel} from "@/models/token.model";
import {UserInfo} from "@/models/userInfo.models";
import {Criteria} from "@/models/criteria.models";
import {UserInfoDetail} from "@/models/userInfoDetail.models";
import {EvaluationDetail} from "@/models/evaluationDetail.models";
import {EvaluationInfo} from "@/models/evaluationInfo.models";
import {MedicalIncident} from "@/models/medicalIncident.models";
import {UserList} from "@/models/userList.models";
import {EvalPatient} from "@/models/evalPatient";

const resource = '/eval';

export default new class{
    getList(date: number): Promise<AxiosResponse<UserInfo[]>>{
        return Repository.get<any>(`${resource}/getList/${date}`)
    }
    getAll(): Promise<AxiosResponse<UserList[]>>{
        return Repository.get<any>(`${resource}/getall`)
    }
    getCriteria(team: string): Promise<AxiosResponse<Criteria[]>>{
        return Repository.get<any>(`${resource}/listcriteria/${team}`)
    }
    getUserInfo(id: number): Promise<AxiosResponse<UserInfoDetail>>{
        return Repository.get<any>(`${resource}/getUserInfo/${id}`)
    }
    evaluationEmployee(evaldetail: EvaluationDetail): Promise<any>{
        return Repository.post<any>(`${resource}/evalemploy`, evaldetail);
    }
    getEvaluation(date: number): Promise<AxiosResponse<any>>{
        return Repository.get<any>(`${resource}/geteval/${date}`)
    }
    getEvaluationById(id: number, date: number): Promise<AxiosResponse<any>>{
        return Repository.get<any>(`${resource}/getevalbyid/${id}/${date}`)
    }
    createMedicalIncident(medicalincident: MedicalIncident): Promise<AxiosResponse<any>>{
        return Repository.post<any>(`${resource}/medicalincident`, medicalincident);
    }
    getListMedical(dateFrom: number, dateTo: number, team: string): Promise<AxiosResponse<any>>{
        return Repository.get<any>(`${resource}/listmedical/${dateFrom}/${dateTo}/${team}`)
    }
    getFileName(id: number): Promise<AxiosResponse<any>>{
        return Repository.get<any>(`${resource}/exportmedical/${id}`)
    }
    createEvalPatient(evalPatient: EvalPatient): Promise<AxiosResponse<any>>{
        return Repository.post<any>(`${resource}/evalPatient`, evalPatient);
    }
    getListEvalPatient(dateFrom: number, dateTo: number): Promise<AxiosResponse<any>>{
        return Repository.get<any>(`${resource}/listevalPatient/${dateFrom}/${dateTo}/`);
    }
    getListVideoDetail(): Promise<AxiosResponse<any>>{
        return Repository.get<any>(`${resource}/getlistvideodetail/`);
    }
    getListPatientCount(dateFrom: number, dateTo: number): Promise<AxiosResponse<any>>{
        return Repository.get<any>(`${resource}/getlistcounteval/${dateFrom}/${dateTo}/`);
    }
    getListPatientCountInMonth(): Promise<AxiosResponse<any>>{
        return Repository.get<any>(`${resource}/getlistcountevalinmonth`);
    }
    getPatientCountInYear(): Promise<AxiosResponse<any>>{
        return Repository.get<any>(`${resource}/getcountevalinyear`);
    }
    getListPatientCountInYear(id: number): Promise<AxiosResponse<any>>{
        return Repository.get<any>(`${resource}/getlistcountevalinyear/${id}/`);
    }
    getCountListMedicalIncident(dateFrom: number, dateTo: number): Promise<AxiosResponse<any>>{
        return Repository.get<any>(`${resource}/getCountListMedicalIncidents/${dateFrom}/${dateTo}/`);
    }
    getEvalPatientById(id: number): Promise<AxiosResponse<any>>{
        return Repository.get<any>(`${resource}/getEvalPatientById/${id}`);
    }
}
