import Repository from './Repository';
import {AxiosResponse} from "axios";
import {UserInfo} from "@/models/userInfo.models";
import {EvaluationCriteria} from "@/models/evaluationCriteria.models";
import {EvaluationTeam} from "@/models/evaluationTeam.models";
import {EvaluationTeamUpdate} from "@/models/evaluationTeamUpdate.models";

const resource = '/evalcriteria';

export default new class{
    create(evaluationCriteria: EvaluationCriteria): Promise<AxiosResponse<EvaluationCriteria>>{
        return Repository.post<any>(`${resource}`, evaluationCriteria)
    }
    getAllByTime(quy: string, nam: string): Promise<AxiosResponse<any>>{
    return Repository.get<any>(`${resource}/all/${quy}/${nam}`)
    }
    getAll(): Promise<AxiosResponse<any>>{
    return Repository.get<any>(`${resource}/evalall`)
    }
    getById(id): Promise<AxiosResponse<any>>{
        return Repository.get<any>(`${resource}/evalinfo/${id}`)
    }
    createEvalTeam(evaluationTeam: EvaluationTeam): Promise<AxiosResponse<any>>{
        return Repository.post<any>(`${resource}/evalteam`, evaluationTeam)
    }
    getInfoById(id: number, quy: string, nam: string): Promise<AxiosResponse<any>>{
        return Repository.get<any>(`${resource}/getinfobyid/${id}/${quy}/${nam}`)
    }
    update(evaluationTeamUpdate: EvaluationTeamUpdate): Promise<AxiosResponse<any>>{
        return Repository.put<any>(`${resource}/updateeval`, evaluationTeamUpdate)
    }
    getListEvalTeamCount(nam: string): Promise<AxiosResponse<any>>{
        return Repository.get<any>(`${resource}/getevalteamcount/${nam}`)
    }
}
