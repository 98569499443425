
    import {MedicalIncident} from "../models/medicalIncident.models";
    import {onMounted, ref} from 'vue';
    import {Team} from "@/models/team.models";
    import {useStore} from "vuex";
    import EvaluationRepository from "@/services/EvaluationRepository";
    import TeamRepository from "@/services/TeamRepository";
    import {UserList} from "@/models/userList.models";
    import {useToast} from "primevue/usetoast";
    import error from "devextreme/file_management/error";

    export default {
        setup() {
            const medicalIncident = ref({} as MedicalIncident);
            const store = useStore();
            const toast = useToast();
            const baoCaoSC = ref([
                { Name: 'Bắt Buộc'},
                { Name: 'Tự Nguyện'},
            ]);
            const donViBC = ref([] as Team[]);
            const noiXayRaSC = ref([] as Team[]);
            medicalIncident.value.nguoi_lap_bc = store.state.fullName;
            TeamRepository.getTeams()
                .then((response) => {
                    noiXayRaSC.value = donViBC.value = response.data;
                })
                .catch();
            const doiTuongXayRaSC = ref([
                { Name: 'Người bệnh'},
                { Name: 'Người nhà/khách đến thăm'},
                { Name: 'Nhân viên y tế'},
                { Name: 'Trang thiết bị/cơ sở hạ tầng'},
            ]);
            const danhSachTuyChon = ref([
                { Name: 'Có'},
                { Name: 'Không'},
                { Name: 'Không ghi nhận'},
            ]);
            const thongTinNguoiBC = ref([
                { Name: 'Bác sĩ(chức danh)'},
                { Name: 'Điều dưỡng(chức danh)'},
                { Name: 'Khác'},
                { Name: 'Người bệnh'},
                { Name: 'Người nhà/khách đến thăm'},
            ]);
            const checkPermission = ref(false);
            const timer = ref(new Date());
            const listUser = ref([] as UserList[]);
            const loadingBar = ref(false);
            EvaluationRepository.getAll()
                .then((response) => {
                    listUser.value = response.data;
                })
                .catch()
                .finally(
                    //function(){
                    //setTimeout(loadList, 1 * 1000);}
                );

            if((store.state.permission == 'Admin')||(store.state.permission == 'Director')){
                checkPermission.value = true;
            }

            const addMedicalIncident = () => {
                loadingBar.value = true;
                EvaluationRepository.createMedicalIncident(medicalIncident.value)
                    .then((response) => {
                        loadingBar.value = false;
                        toast.add({
                            severity: 'success',
                            summary: 'Thành công',
                            detail: 'Báo cáo y khoa thành công.',
                            life: 2500
                        });
                    })
                    .catch(error => {
                        loadingBar.value = false;
                    })
                    .finally(
                        //function(){
                        //setTimeout(loadList, 1 * 1000);}
                    );
            };
            const valid = () => {
                return medicalIncident.value.dieu_tri && medicalIncident.value.danh_gia_ban_dau && medicalIncident.value.de_xuat_giai_phap && medicalIncident.value.doi_tuong_xay_ra_sc
                    && medicalIncident.value.don_vi_bc && medicalIncident.value.ghi_nhan_vao_hs && medicalIncident.value.hinh_thuc_bc && medicalIncident.value.ho_ten_nguoi_bc
                    && medicalIncident.value.mo_ta_sc  && medicalIncident.value.ngay_bc  && medicalIncident.value.nguoi_chung_kien1  && medicalIncident.value.nguoi_chung_kien2
                    && medicalIncident.value.nguoi_lap_bc  && medicalIncident.value.phan_loai_ban_dau_ve_sc  && medicalIncident.value.so_bc && medicalIncident.value.so_benh_an
                    && medicalIncident.value.so_dien_thoai_bc;
            }

            // const autoUpdate = () => {
            //
            // };

            return {
                addMedicalIncident,
                baoCaoSC,
                donViBC,
                doiTuongXayRaSC,
                noiXayRaSC,
                danhSachTuyChon,
                thongTinNguoiBC,
                timer,
                medicalIncident,
                listUser,
                loadingBar,
                valid,
            }
        }

    }
