

import { computed, ref } from 'vue'
import AuthRepository from '@/services/AuthRepository'
import { useToast } from "primevue/usetoast";
import { useRouter } from 'vue-router'
import {Register} from "../models/register";
import {useStore} from "vuex";
import TeamRepository from "../services/TeamRepository";
import {Team} from "@/models/team.models";
import {UserPermission} from "@/models/permission.models";

export default {

  setup(){
    const router = useRouter();
    const register = ref({} as Register);
    const toast = useToast();
    const store = useStore();
    const error = ref(null);
    const emailPermission = ref([
      {label: 'Không cho phép', value: 'no'},
      {label: 'Cho phép', value: 'yes'},
    ]);
    const emailPer = ref("");
    const nckhPer = ref("");
    nckhPer.value = "employee";
    emailPer.value = "no";

    const valid = computed(()=> register.value.password && register.value.username && register.value.team && register.value.fullname && register.value.address && register.value.cmnd);

    if(!(store.state.permission == 'Admin')){
      router.push({
        name: 'home'
      });
    }
    const teams = ref({} as Team[]);

    register.value.permission = 'usr';
    const userPermission = ref({} as UserPermission[]);

    const addressHospital = ref([
      { label: 'Cơ Sở 1', value: 'cs1' },
      { label: 'Cơ Sở 2', value: 'cs2' },
    ]);
    const nckh = ref([
      { label: 'Quản lý(duyệt đề tài)', value: 'admin' },
      { label: 'Khoa phòng(đăng ký đề tài)', value: 'user' },
      { label: 'Nhân viên(xem đề tài)', value: 'employee' },
    ]);

    TeamRepository.getTeams()
            .then((response) => {
              teams.value = response.data;
            })
            .catch();

    TeamRepository.getPermission()
            .then((response) => {
              userPermission.value = response.data;
            })
            .catch();
    const doRegister = () => {
      if(register.value.password.length < 6)
      {
        toast.add({
          severity: 'error',
          summary: 'Thất bại',
          detail: 'Mật khẩu không hợp lệ, vui lòng nhập lại mật khẩu',
          life: 2000
        });
      }
      else {
        register.value.emailpermission = emailPer.value;
        register.value.per_nckh = nckhPer.value;
        AuthRepository.registerUser(register.value)
                .then((response) => {
                  toast.add({
                    severity: 'success',
                    summary: 'Đăng ký',
                    detail: 'Đăng ký thành viên thành công vào hệ thống',
                    life: 2000
                  });
                  router.push({
                    name: 'home'
                  });
                })
                .catch(err => {
                  toast.add({
                    severity: 'error',
                    summary: 'Lỗi',
                    detail:err.response.data,
                    life: 2000
                  });
                });
      };
    }

    return {
      register,
      doRegister,
      valid,
      teams,
      userPermission,
      error,
      addressHospital,
      emailPermission,
      emailPer,
      nckh,
      nckhPer,
    }
  }
}

