

import {computed, onMounted, ref} from 'vue'
import {useToast} from "primevue/usetoast";

import EvaluationRepository from "@/services/EvaluationRepository";
import {MedicalIncidentCount} from "@/models/medicalIncidentCount.models";

export default {
  setup() {
    const date = ref();
    const date2 = ref();
    const toast = useToast();
    const medicalIncidentCount = ref([] as MedicalIncidentCount[]);
    const showStatistical = ref(false);

    const selectCalendar = () => {
      if((date.value != null)&&(date2.value != null)) {
        EvaluationRepository.getCountListMedicalIncident(date.value/1000, date2.value/1000)
            .then((response) => {
              medicalIncidentCount.value = response.data;
              showStatistical.value = true;
            })
            .catch(err => {
              toast.add({
                severity: 'error',
                summary: 'Lỗi',
                detail:err.response.data,
                life: 2000
              });
            });
      }
    };

    const totalData = computed(() => {
      const data = medicalIncidentCount.value;

      return {
        labels: data.map(x => x.time),
        datasets: [
          {
            label: 'Tổng số báo cáo sự cố y khoa của từng tháng',
            data: data.map(x => x.count),
            backgroundColor: '#42A5F5',
          }
        ]
      }
    });

    return {
      date,
      selectCalendar,
      date2,
      showStatistical,
      totalData,
    }
  }

}
