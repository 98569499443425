
    import {ref} from 'vue'
    import {useToast} from "primevue/usetoast";
    import moment from 'moment';
    import {useStore} from "vuex";
    import {EvaluationCriteria} from "@/models/evaluationCriteria.models";
    import EvaluationCriteriaRepository from "@/services/EvaluationCriteriaRepository";
    import {Team} from "@/models/team.models";
    import TeamRepository from "@/services/TeamRepository";
    import {EvaluationTeam} from "@/models/evaluationTeam.models";
    import {EvaluationCriteriaShort} from "@/models/evaluationCriteriaShort.models";
    import {EvaluationTeamUpdate} from "@/models/evaluationTeamUpdate.models";
    import jsPDF from 'jspdf';
    import font from "@/utils/font";
    import autoTable from "jspdf-autotable";
    import {FilterMatchMode} from "primevue/api";

    export default {
        setup() {
            const criteriaTeam = ref([] as EvaluationCriteriaShort[]);
            const toast = useToast();
            const criterial = ref({} as EvaluationCriteria);
            const showDetail = ref(false);
            const showTable = ref(false);
            const confirmEval = ref(false);
            const title = ref("");
            const mucDanhGia = ref([
                { name: 'Mức 1', value: "1"},
                { name: 'Mức 2', value: "2"},
                { name: 'Mức 3', value: "3"},
                { name: 'Mức 4', value: "4"},
                { name: 'Mức 5', value: "5"},
            ]);
            const quy = ref([
                { name: 'Quý 1', value: "1"},
                { name: 'Quý 2', value: "2"},
                { name: 'Quý 3', value: "3"},
                { name: 'Quý 4', value: "4"},
            ]);
            const evaUpdate = ref({} as EvaluationTeamUpdate);
            const evalTeam = ref({} as EvaluationTeam);
            const nam = ref(new Date());

            const teams = ref({} as Team[]);

            TeamRepository.getTeams()
                .then((response) => {
                    teams.value = response.data;
                })
                .catch();

            const criterialDetailClick = (id: number) => {
                evaUpdate.value = {} as EvaluationTeamUpdate;
                confirmEval.value = false;
                EvaluationCriteriaRepository.getById(id)
                    .then(response => {
                        evalTeam.value.id_eval = id;
                        criterial.value = response.data;

                        EvaluationCriteriaRepository.getInfoById(id, evalTeam.value.quy, timeEval())
                            .then((response) => {
                                showDetail.value = true;
                                const temp = ref({} as EvaluationTeam);
                                evaUpdate.value = response.data;
                                temp.value = response.data;
                                evalTeam.value.khoachinh = temp.value.khoachinh;
                                evalTeam.value.khoaphu = temp.value.khoaphu;
                                evalTeam.value.ketqua = temp.value.ketqua;
                            })
                            .catch();
                        title.value = criterial.value.ma + " - " + criterial.value.tieu_de;
                    })
                    .catch(() => {
                        toast.add({
                            severity: 'error',
                            summary: 'Thất bại',
                            detail: 'Không thể tải thông tin người được đánh giá.',
                            life: 1000
                        });
                    })
                    .finally(() => {
                    });
            };

            const submit = () => {
                evalTeam.value.nam = timeEval();
                EvaluationCriteriaRepository.createEvalTeam(evalTeam.value)
                    .then(response => {
                        toast.add({
                            severity: 'success',
                            summary: 'Thành công',
                            detail: 'Đánh giá thành công.',
                            life: 1000
                        });
                        confirmEval.value = true;
                        loadEval();
                    })
                    .catch(() => {
                        toast.add({
                            severity: 'error',
                            summary: 'Thất bại',
                            detail: 'Không thể tải thông tin người được đánh giá.',
                            life: 1000
                        });
                    })
                    .finally(() => {
                    });
            };

            const udpate = () => {
                evaUpdate.value.khoachinh = evalTeam.value.khoachinh;
                evaUpdate.value.khoaphu = evalTeam.value.khoaphu;
                evaUpdate.value.ketqua = evalTeam.value.ketqua;
                EvaluationCriteriaRepository.update(evaUpdate.value)
                    .then(response => {
                        toast.add({
                            severity: 'success',
                            summary: 'Thành công',
                            detail: 'Đánh giá thành công.',
                            life: 1000
                        });
                        confirmEval.value = true;
                        loadEval();
                    })
                    .catch(() => {
                        toast.add({
                            severity: 'error',
                            summary: 'Thất bại',
                            detail: 'Không thể tải thông tin người được đánh giá.',
                            life: 1000
                        });
                    })
                    .finally(() => {
                    });
            };

            const timeEval = () => {
                return moment(evalTeam.value.nam).format('YYYY');
            }

            const loadEval = () => {
                EvaluationCriteriaRepository.getAllByTime(evalTeam.value.quy, timeEval())
                    .then(response => {
                        criteriaTeam.value = response.data;
                        showTable.value = true;
                    })
                    .catch(() => {
                        toast.add({
                            severity: 'error',
                            summary: 'Thất bại',
                            detail: 'Không thể tải thông tin người được đánh giá.',
                            life: 1000
                        });
                    })
                    .finally(() => {
                    });
            };
            const createPDF = () => {
                const doc = new jsPDF('l', 'px', 'a4');
                let data :any[] = [];
                const count = ref(0);
                criteriaTeam.value.forEach(x => {
                    count.value++;
                    let item = [count.value, x.ma, x.tieude, x.khoa, x.xeploai];
                    data.push(item);
                });
                const TimesNewRoman = font();

                doc.addFileToVFS("Fontvn.ttf", TimesNewRoman);
                doc.addFont("Fontvn.ttf", "Fontvn", "normal");
                doc.setFont("Fontvn");

                doc.text('ĐÁNH GIÁ BỆNH VIỆN: TRONG QUÝ ' + evalTeam.value.quy + ' NĂM ' +  timeEval(), 320, 20,  { align: 'center' });
                autoTable(doc, {styles: {font: "Fontvn", fontSize: 13,},
                    headStyles: {fillColor: [65, 127, 181]},
                    theme: 'grid',
                    head: [["STT", "Mã", "Tiêu đề", "Khoa/Phòng", "Xếp Loại"]],
                    body: data,},)
                doc.save('DanhGiaBenhVien.pdf');
            };
            const valid = () => {
                return evalTeam.value.quy && evalTeam.value.nam;
            };
            const filters = ref({
                'global': {value: null, matchMode: FilterMatchMode.CONTAINS}
            });

            const clearFilter = () => {
                initFilters();
            };

            const initFilters = () => {
                filters.value = {
                    'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
                }
            };

            return {
                criteriaTeam,
                criterialDetailClick,
                criterial,
                showDetail,
                title,
                mucDanhGia,
                teams,
                evalTeam,
                submit,
                quy,
                nam,
                loadEval,
                valid,
                showTable,
                evaUpdate,
                udpate,
                confirmEval,
                createPDF,
                filters,
                clearFilter,
                initFilters,
            }
        }
    }
