import Repository from './Repository';
import {AxiosResponse} from "axios";
import {EmailForm} from "@/models/emailForm.models";
import {EmailDetail} from "@/models/emailDetail";
import {EmailCount} from "@/models/EmailCount.models";
import {DirectingForm} from "@/models/directingForm.models";

const resource = '/email';

export default new class{
    sendEmail(emailForm: EmailForm): Promise<AxiosResponse<any>>{
        return Repository.post<any>(`${resource}/sendemail`,emailForm);
    }
    createEmail(email: EmailDetail): Promise<AxiosResponse<any>>{
        return Repository.post<any>(`${resource}/createemail`,email);
    }
    getEmailList(): Promise<AxiosResponse<any>>{
        return Repository.get<EmailDetail[]>(`${resource}/getemaillist`);
    }
    getCount(contact: number): Promise<AxiosResponse<any>>{
        return Repository.get<EmailCount[]>(`${resource}/getcount/${contact}`);
    }
    createDirecting(directingForm: DirectingForm): Promise<AxiosResponse<any>>{
        return Repository.post<any>(`${resource}/createdirecting`,directingForm);
    }
    getListDirecting(): Promise<AxiosResponse<any>>{
        return Repository.get<any>(`${resource}/getlistdirecting`,);
    }
    getListEmailCount(dateFrom: number, dateTo: number): Promise<AxiosResponse<any>>{
        return Repository.get<any>(`${resource}/getlistcountemail/${dateFrom}/${dateTo}/`);
    }
}
