
import {ref} from 'vue'
import {useToast} from "primevue/usetoast";
import AuthRepository from "@/services/AuthRepository";
import {useStore} from "vuex";
import router from "@/router";
import {FilterMatchMode} from "primevue/api";
import {UserListInfo} from "@/models/userListInfo.models";
import {Team} from "@/models/team.models";
import TeamRepository from "@/services/TeamRepository";
import {UserPermission} from "@/models/permission.models";
import moment from "moment";

export default {
  setup() {
    const toast = useToast();
    const isLoading = ref(false);
    const list = ref([] as UserListInfo[]);
    const teams = ref({} as Team[]);
    const userPermission = ref({} as UserPermission[]);

    TeamRepository.getTeams()
        .then((response) => {
          teams.value = response.data;
          TeamRepository.getPermission()
              .then((response) => {
                userPermission.value = response.data;
                AuthRepository.userListInfo()
                    .then((response) => {
                      list.value = response.data;
                      list.value.forEach(x => {
                        teams.value.forEach(y => {
                          if(y.code == x.team) x.team = y.name
                        });
                        userPermission.value.forEach(z => {
                          if(z.value == x.permission) x.permission = z.name;
                        })
                      })
                    }).catch(() => {
                  toast.add({
                    severity: 'error',
                    summary: 'Thất bại',
                    detail: 'Không thể tải danh sách lịch đặt chổ.',
                    life: 1000
                  });
                })
              })
              .catch();
        })
        .catch();



    const filters = ref({
      'global': {value: null, matchMode: FilterMatchMode.CONTAINS}
    });

    const clearFilter = () => {
      initFilters();
    };

    const initFilters = () => {
      filters.value = {
        'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
      }
    };
    const formatDateTime = (date) => {
      return moment(String(date)).format('DD/MM/YYYY');
    };

    return {
      list,
      filters,
      clearFilter,
      initFilters,
      formatDateTime,
    }
  }
}
