

    import { ref } from 'vue'
    import axios from "axios";
    import {useToast} from "primevue/usetoast";
    import {EmailForm} from "@/models/emailForm.models";
    import {FileDetail} from "@/models/fileDetail.models";
    import {EmailDetail} from "@/models/emailDetail";
    import EmailRepository from "@/services/EmailRepository";
    import {useConfirm} from "primevue/useconfirm";
    import router from "@/router";
    import {useStore} from "vuex";
    import {DirectingShort} from "@/models/directingShort.models";

    export default {

        setup(){

            const downLoad = ref(false);
            const loadingBar = ref(false);
            const toast = useToast();
            const emaiForm = ref({} as EmailForm);
            const display = ref(true);
            const listLink = ref([] as string[]);
            const Email = ref([] as EmailDetail[]);
            const store = useStore();
            const listDirecting = ref([] as DirectingShort[]);

            if(!(store.state.emailPermission == 'yes')){
                router.push({
                    name: 'home'
                });
            }

            EmailRepository.getEmailList()
                .then((response) => {
                    Email.value = response.data;
                })
                .catch()
                .finally(
                    //function(){
                    //setTimeout(loadList, 1 * 1000);}
                );

            EmailRepository.getListDirecting()
                .then((response) => {
                    listDirecting.value = response.data;
                })
                .catch()
                .finally(
                    //function(){
                    //setTimeout(loadList, 1 * 1000);}
                );
            const sendEmail = () => {
                loadingBar.value = true;
                        //console.log("$$$$$$$$$$$##############^^^^^^^^^^^^^^ sendEmail emaiForm: " + JSON.stringify(emaiForm.value));
                        EmailRepository.sendEmail(emaiForm.value)
                            .then((response) => {
                                loadingBar.value = false;
                                confirmSuccess();
                            })
                            .catch(
                                err => {
                                    loadingBar.value = false;
                                }
                            )
                            .finally(
                                //function(){
                                //setTimeout(loadList, 1 * 1000);}
                            );
            };
            const reset = () => {
                emaiForm.value.emails = [];
                emaiForm.value.tieude = "";
                emaiForm.value.noidung = "";
            };
            const valid = () => {
                return emaiForm.value.emails && emaiForm.value.noidung && emaiForm.value.tieude && (emaiForm.value.emails.length != 0);
            };
            const confirm = useConfirm();
            const confirmSuccess = () => {
                confirm.require({
                    message: 'Gởi chỉ đạo tuyến thành công',
                    header: 'Xác nhận',
                    icon: 'pi pi-check-circle',
                    acceptLabel: 'Gởi chỉ đạo mới',
                    rejectLabel: 'Gởi tiếp',
                    accept: () => {
                        reset();
                    },
                    reject: () => {
                    }
                });
            };

            return {
                sendEmail,
                downLoad,
                loadingBar,
                emaiForm,
                display,
                reset,
                Email,
                valid,
                listDirecting,
            }
        }
    }

