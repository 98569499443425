

import { computed, ref } from 'vue'
import AuthRepository from '@/services/AuthRepository'
import { useToast } from "primevue/usetoast";
import { useRouter } from 'vue-router'
import {Register} from "../models/register";
import {useStore} from "vuex";
import TeamRepository from "../services/TeamRepository";
import {Team} from "@/models/team.models";
import {UserPermission} from "@/models/permission.models";
import {UserDetail} from "@/models/userDetail.models";
import authRepository from "@/services/AuthRepository";
import {UserUpdate} from "@/models/userUpdate.model";
import {useConfirm} from "primevue/useconfirm";
import moment from "moment";

export default {
  props: {
    id: Number,
  },

  setup(props) {
    const router = useRouter();
    const userUpdate = ref({} as UserUpdate);
    const toast = useToast();
    const store = useStore();
    const userDetail = ref({} as UserDetail);
    const error = ref(null);
    const emailPermission = ref([
      {label: 'Không cho phép', value: 'no'},
      {label: 'Cho phép', value: 'yes'},
    ]);
    const nckhPer = ref("");
    nckhPer.value = "user";
    const emailPer = ref("");
    emailPer.value = "no";
    userUpdate.value.password = "";
    const nckh = ref([
      { label: 'Quản lý(duyệt đề tài)', value: 'admin' },
      { label: 'Khoa phòng(đăng ký đề tài)', value: 'user' },
      { label: 'Nhân viên(xem đề tài)', value: 'employee' },
    ]);

    authRepository.getUserById(props.id)
    .then((response) => {userDetail.value = response.data;
      console.log("################ getUserById userDetail: " + JSON.stringify(userDetail.value));
      userUpdate.value.fullname = userDetail.value.full_name;
      userUpdate.value.username = userDetail.value.username;
      userUpdate.value.team = userDetail.value.team;
      userUpdate.value.permission = userDetail.value.permission;
      emailPer.value = userUpdate.value.email_permission = userDetail.value.email_permission;
      userUpdate.value.id = userDetail.value.id;
      userUpdate.value.address = userDetail.value.address;
      userUpdate.value.birthday = userDetail.value.birthday;
      userUpdate.value.cmnd = userDetail.value.cmnd;
      userUpdate.value.email = userDetail.value.email;
      nckhPer.value = userDetail.value.per_nckh;
      console.log("################ getUserById userUpdate: " + JSON.stringify(userUpdate.value));
    })
    .catch();

    const valid = computed(()=> userUpdate.value.password && userUpdate.value.username && userUpdate.value.team && userUpdate.value.fullname && userUpdate.value.address);

    if(!(store.state.permission == 'Admin')){
      router.push({
        name: 'home'
      });
    }
    const teams = ref({} as Team[]);

    const userPermission = ref({} as UserPermission[]);

    const addressHospital = ref([
      { label: 'Cơ Sở 1', value: 'cs1' },
      { label: 'Cơ Sở 2', value: 'cs2' },
    ]);

    TeamRepository.getTeams()
            .then((response) => {
              teams.value = response.data;
            })
            .catch();

    TeamRepository.getPermission()
            .then((response) => {
              userPermission.value = response.data;
            })
            .catch();
    const doUpdate = () => {
      if(userUpdate.value.password.length < 6 && userUpdate.value.password.length > 0)
      {
        toast.add({
          severity: 'error',
          summary: 'Thất bại',
          detail: 'Mật khẩu không hợp lệ, vui lòng nhập lại mật khẩu',
          life: 2000
        });
      }
      else {
        console.log("#######$$$$$$$$$$$%%%%%%%%%%%%%%%%% emailPer: " + JSON.stringify(emailPer.value));
        userUpdate.value.email_permission = emailPer.value;
        userUpdate.value.per_nckh = nckhPer.value;
        console.log("#######$$$$$$$$$$$%%%%%%%%%%%%%%%%% doUpdate: " + JSON.stringify(userUpdate.value));
        AuthRepository.userUpdate(userUpdate.value)
                .then((response) => {
                  console.log("#######$$$$$$$$$$$%%%%%%%%%%%%%%%%% doUpdate222222: " + JSON.stringify(userUpdate.value));
                  toast.add({
                    severity: 'success',
                    summary: 'Cập nhật',
                    detail: 'Cập nhật thông tin tài khoản thành công',
                    life: 2000
                  });
                  // router.push({
                  //   name: 'home'
                  // });
                })
                .catch(err => {
                  toast.add({
                    severity: 'error',
                    summary: 'Lỗi',
                    detail:err.response.data,
                    life: 2000
                  });
                });
      };
    };
    const confirm = useConfirm();
    const del = (event) => {
      confirm.require({
        target: event.currentTarget,
        message: 'Bạn có chắc muốn xoá tài khoản này không?',
        icon: 'pi pi-info-circle',
        acceptClass: 'p-button-danger',
        rejectClass: 'p-button-outlined',
        acceptLabel: 'Đồng ý',
        rejectLabel: 'Huỷ',
        accept: () => {
          AuthRepository.deleteUser(userUpdate.value.id)
              .then(response => {
                toast.add({severity:'info', summary:'Confirmed', detail:'Tài khoản đã được xoá thành công', life: 3000});
                router.push({
                  name: 'home',
                });
              })
              .finally(()=>{
              });
        },
        reject: () => {
        }
      });
    };

    const formatDateTime = (date) => {
      return moment(String(date)).format('DD/MM/YYYY');
    };;

    return {
      userUpdate,
      doUpdate,
      valid,
      teams,
      userPermission,
      error,
      addressHospital,
      emailPermission,
      emailPer,
      del,
      formatDateTime,
      nckh,
      nckhPer
    }
  }
}

