

import { Login } from '../models/login'
import { computed} from 'vue'
import { ref } from 'vue'

export default {

  setup () {
    const login = ref({} as Login);
    const userName = ref("");
    const password = ref("");

    const valid = computed(() => password.value && userName.value)

    return {
      login,
      valid,
      password,
      userName,
    }
  }
}

