

import { computed, ref } from 'vue'
import { useToast } from "primevue/usetoast";
import { useRouter } from 'vue-router'
import {useStore} from "vuex";
import EmailRepository from "@/services/EmailRepository";
import {EmailDetail} from "@/models/emailDetail";
import {EmailCount} from "@/models/EmailCount.models";
import {EmailListCount} from "@/models/EmailListCount.models";

export default {

  setup(){
    const router = useRouter();
    const toast = useToast();
    const store = useStore();
    const emailDetail = ref({} as EmailDetail);
    const countYear = ref(false);
    const countYearWithContact = ref(false);
    const countYearBetweenContact = ref(false);
    if(!(store.state.emailPermission == 'yes')){
      router.push({
        name: 'home'
      });
    }
    const countAll = ref(0);
    const countAllWithContact = ref(0);
    const emailListCount = ref([] as EmailListCount[]);

    const valid = () => {
      return emailDetail.value.name && emailDetail.value.email;
    };
    const emailCountWithContact = ref([] as EmailCount[]);
    const emailCount = ref([] as EmailCount[]);

    EmailRepository.getCount(1)
        .then((response) => {
          //teams.value = response.data;
          emailCount.value = response.data;
          emailCount.value.forEach(x => countAll.value = countAll.value + x.count);
        })
        .catch(err => {
          toast.add({
            severity: 'error',
            summary: 'Lỗi',
            detail:err.response.data,
            life: 2000
          });
        });
    EmailRepository.getCount(2)
        .then((response) => {
          emailCountWithContact.value = response.data;
          emailCountWithContact.value.forEach(x => countAllWithContact.value = countAllWithContact.value + x.count);
        })
        .catch(err => {
          toast.add({
            severity: 'error',
            summary: 'Lỗi',
            detail:err.response.data,
            life: 2000
          });
        });
    const totalData = computed(() => {
      const data = emailCount.value;

      return {
        labels: data.map(x => x.name),
        datasets: [
          {
            label: 'Tổng số chỉ đạo gởi trong tháng',
            data: data.map(x => x.count),
            fill: false,
            borderColor: '#42A5F5',
          }
        ]
      }
    });
    const totalDataContact = computed(() => {
      const data = emailCountWithContact.value;

      return {
        labels: data.map(x => x.name),
        datasets: [
          {
            label: 'Tổng số chỉ đạo gởi trong tháng',
            data: data.map(x => x.count),
            fill: false,
            borderColor: '#66BB6A',
          }
        ]
      }
    });

    const slackedData = computed(() => {
      const data = emailCount.value;
      const dataWithContact = emailCountWithContact.value;

      return {
        labels: data.map(x => x.name),
        datasets: [{
          type: 'bar',
          label: 'Chỉ đạo đến các bệnh viện',
          backgroundColor: '#42A5F5',
          data: data.map(x => x.count)
        }, {
          type: 'bar',
          label: 'Chỉ đạo theo dạng hợp đồng',
          backgroundColor: '#66BB6A',
          data: dataWithContact.map(x => x.count)
        }
        ]
      }
    });

    const totalDataWithPie = computed(() => {
      return {
        labels: ['Chuyển giao cho các bệnh viện', 'Chuyển giao theo dạng hợp đồng'],
        datasets: [
          {
            label: 'Thống kê chuyển giao',
            data: [countAll.value, countAllWithContact.value],
            backgroundColor: ["#42A5F5","#66BB6A"],
            hoverBackgroundColor: ["#42A5F5","#66BB6A"]
          }
        ]
      }
    });
    const clickCountYear = (contact: number) => {
      countYear.value = false;
      countYearWithContact.value = false;
      countYearBetweenContact.value = false;
      if(contact == 1)
        countYear.value = true;
      else if(contact == 2)
        countYearWithContact.value = true;
      else
        countYearBetweenContact.value = true;
    };
    const date = ref();
    const date2 = ref();
    const showStatistical = ref(false);
    const selectCalendar = () => {
      if((date.value != null)&&(date2.value != null)) {
        EmailRepository.getListEmailCount(date.value/1000, date2.value/1000)
            .then((response) => {
              emailListCount.value = response.data;
              showStatistical.value = true;
            })
            .catch(err => {
              toast.add({
                severity: 'error',
                summary: 'Lỗi',
                detail:err.response.data,
                life: 2000
              });
            });
      }
    };
    const totalListData = computed(() => {
      const data = emailListCount.value;

      return {
        labels: data.map(x => x.thang),
        datasets: [
          {
            label: 'Chuyển giao cho các bệnh viện',
            data: data.map(x => x.cdbv),
            fill: false,
            borderColor: '#fd7b6a',
          },
          {
            label: 'Chuyển giao theo dạng hợp đồng',
            data: data.map(x => x.hdcm),
            fill: false,
            borderColor: '#01e0fd',
          },
        ]
      }
    });

    const stackedOptions = ref(
        {
          plugins: {
            tooltips: {
              mode: 'index',
              intersect: false
            },
            legend: {
              labels: {
                color: '#495057'
              }
            }
          },
          scales: {
            x: {
              stacked: true,
              ticks: {
                color: '#495057'
              },
              grid: {
                color: '#ebedef'
              }
            },
            y: {
              stacked: true,
              ticks: {
                color: '#495057'
              },
              grid: {
                color: '#ebedef'
              }
            }
          }
        }
    );
    clickCountYear(1);
    clickCountYear(2);
    clickCountYear(3);
    const horizontalOptions = ref(
        {
          indexAxis: 'y',
          plugins: {
            legend: {
              labels: {
                color: '#495057'
              }
            }
          },
          scales: {
            x: {
              ticks: {
                color: '#495057'
              },
              grid: {
                color: '#ebedef'
              }
            },
            y: {
              ticks: {
                color: '#495057'
              },
              grid: {
                color: '#ebedef'
              }
            }
          }
        }
    );

    return {
      emailDetail,
      valid,
      totalData,
      countYear,
      clickCountYear,
      totalDataContact,
      countYearWithContact,
      countYearBetweenContact,
      totalDataWithPie,
      date,
      date2,
      totalListData,
      selectCalendar,
      showStatistical,
      slackedData,
      stackedOptions,
      horizontalOptions,
    }
  }
}

