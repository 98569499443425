

import {onMounted, ref} from 'vue'
import {useToast} from "primevue/usetoast";

import {useStore} from "vuex";
import EvaluationRepository from "@/services/EvaluationRepository";
import {useRouter} from "vue-router";
import moment from "moment";
import {EvalPatientDetail} from "@/models/evalPatientDetail";
import {FilterMatchMode} from "primevue/api";

export default {
  setup() {
      const store = useStore();
      const date = ref(new Date());
    const router = useRouter();
    const checkPermission = ref(false);
    const listEvalPatient = ref([] as EvalPatientDetail[]);
    const toast = useToast();

      const selectCalendar = () => {
          if((date.value[0] != null)&&(date.value[1] != null)) {
              EvaluationRepository.getListEvalPatient(date.value[0]/1000, date.value[1]/1000)
                  .then((response) => {
                      listEvalPatient.value = response.data;
                  })
                  .catch(err => {
                      toast.add({
                          severity: 'error',
                          summary: 'Lỗi',
                          detail:err.response.data,
                          life: 2000
                      });
                  });
          }
          };
      const formatDateTime = (date) => {
          return moment(String(date)).format('DD/MM/YYYY');
      };
    const evaluation = (id: any) => {
      let routeData = router.resolve({name: 'detailevalofpatient', params: {id: id}});
      window.open(routeData.href, '_blank');
    };
    const filters = ref({
      'global': {value: null, matchMode: FilterMatchMode.CONTAINS}
    });

    const clearFilter = () => {
      initFilters();
    };

    const initFilters = () => {
      filters.value = {
        'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
      }
    };

    return {
        checkPermission,
        date,
        selectCalendar,
        listEvalPatient,
        formatDateTime,
      evaluation,
      filters,
      clearFilter,
      initFilters,
    }
  }

}
