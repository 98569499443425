
    import {ref} from 'vue'
    import {useToast} from "primevue/usetoast";
    import moment from 'moment';
    import {useStore} from "vuex";
    import {EvaluationCriteria} from "@/models/evaluationCriteria.models";
    import EvaluationCriteriaRepository from "@/services/EvaluationCriteriaRepository";
    import {Team} from "@/models/team.models";
    import TeamRepository from "@/services/TeamRepository";
    import {EvaluationTeam} from "@/models/evaluationTeam.models";
    import {EvaluationCriteriaShort} from "@/models/evaluationCriteriaShort.models";
    import {EvaluationTeamUpdate} from "@/models/evaluationTeamUpdate.models";
    import jsPDF from 'jspdf';
    import font from "@/utils/font";
    import autoTable from "jspdf-autotable";
    import {FilterMatchMode} from "primevue/api";

    export default {
        setup() {
            const criteriaTeam = ref([] as EvaluationCriteria[]);
            const toast = useToast();
            const criterial = ref({} as EvaluationCriteria);
            const showDetail = ref(false);
            const showTable = ref(false);
            const confirmEval = ref(false);
            const title = ref("");
            const evaUpdate = ref({} as EvaluationTeamUpdate);
            const evalTeam = ref({} as EvaluationTeam);
            const nam = ref(new Date());

            const teams = ref({} as Team[]);

            TeamRepository.getTeams()
                .then((response) => {
                    teams.value = response.data;
                })
                .catch();

            const criterialDetailClick = (id: number) => {
                evaUpdate.value = {} as EvaluationTeamUpdate;
                confirmEval.value = false;
                EvaluationCriteriaRepository.getById(id)
                    .then(response => {
                        evalTeam.value.id_eval = id;
                        criterial.value = response.data;

                        EvaluationCriteriaRepository.getInfoById(id, evalTeam.value.quy, timeEval())
                            .then((response) => {
                                showDetail.value = true;
                                const temp = ref({} as EvaluationTeam);
                                evaUpdate.value = response.data;
                                temp.value = response.data;
                                evalTeam.value.khoachinh = temp.value.khoachinh;
                                evalTeam.value.khoaphu = temp.value.khoaphu;
                                evalTeam.value.ketqua = temp.value.ketqua;
                            })
                            .catch();
                        title.value = criterial.value.ma + " - " + criterial.value.tieu_de;
                    })
                    .catch(() => {
                        toast.add({
                            severity: 'error',
                            summary: 'Thất bại',
                            detail: 'Không thể tải thông tin người được đánh giá.',
                            life: 1000
                        });
                    })
                    .finally(() => {
                    });
            };

            const timeEval = () => {
                return moment(evalTeam.value.nam).format('YYYY');
            }

            const loadEval = () => {
                EvaluationCriteriaRepository.getAll()
                    .then(response => {
                         criteriaTeam.value = response.data;
                        // criteriaTeam.value = [];
                        showTable.value = true;
                    })
                    .catch(() => {
                        toast.add({
                            severity: 'error',
                            summary: 'Thất bại',
                            detail: 'Không thể tải thông tin người được đánh giá.',
                            life: 1000
                        });
                    })
                    .finally(() => {
                    });
            };
            loadEval();
            const valid = () => {
                return evalTeam.value.quy && evalTeam.value.nam;
            };
            const filters = ref({
                'global': {value: null, matchMode: FilterMatchMode.CONTAINS}
            });

            const clearFilter = () => {
                initFilters();
            };

            const initFilters = () => {
                filters.value = {
                    'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
                }
            };

            return {
                criteriaTeam,
                criterialDetailClick,
                criterial,
                showDetail,
                title,
                teams,
                evalTeam,
                nam,
                loadEval,
                valid,
                showTable,
                evaUpdate,
                confirmEval,
                filters,
                clearFilter,
                initFilters,
            }
        }
    }
